import { __assign, __spreadArrays } from "tslib";
import defaultTranslation from './locales/en-US.json';
import { formatCustomTranslations, formatLocale, getTranslation, loadTranslations, parseLocale } from './utils';
import { FALLBACK_LOCALE } from './config';
import locales from './locales';
import { getLocalisedAmount } from '~/utils/amount-util';
var Language = /** @class */ (function () {
    function Language(locale, customTranslations) {
        if (locale === void 0) { locale = FALLBACK_LOCALE; }
        if (customTranslations === void 0) { customTranslations = {}; }
        this.translations = defaultTranslation;
        var defaultLocales = Object.keys(locales);
        this.customTranslations = formatCustomTranslations(customTranslations, defaultLocales);
        var localesFromCustomTranslations = Object.keys(this.customTranslations);
        this.supportedLocales = __spreadArrays(defaultLocales, localesFromCustomTranslations).filter(function (v, i, a) { return a.indexOf(v) === i; }); // our locales + validated custom locales
        this.locale = formatLocale(locale) || parseLocale(locale, this.supportedLocales) || FALLBACK_LOCALE;
        this.translations = loadTranslations(this.locale, this.customTranslations);
    }
    /**
     * Returns a translated string from a key in the current {@link Language.locale}
     * @param key - Translation key
     * @param options - Translation options
     * @returns Translated string
     */
    Language.prototype.get = function (key, options) {
        var translation = getTranslation(this.translations, key, options);
        if (translation !== null) {
            return translation;
        }
        return key;
    };
    /**
     * Returns a localized string for an amount
     * @param amount - Amount to be converted
     * @param currencyCode - Currency code of the amount
     * @param options - Options for String.prototype.toLocaleString
     */
    Language.prototype.amount = function (amount, currencyCode, options) {
        return getLocalisedAmount(amount, this.locale, currencyCode, options);
    };
    /**
     * Returns a localized string for a date
     * @param date - Date to be localized
     * @param options - Options for {@link Date.toLocaleDateString}
     */
    Language.prototype.date = function (date, options) {
        if (options === void 0) { options = {}; }
        var dateOptions = __assign({ year: 'numeric', month: '2-digit', day: '2-digit' }, options);
        return new Date(date).toLocaleDateString(this.locale, dateOptions);
    };
    return Language;
}());
export { Language };
export default Language;
