import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import OpenInvoice from '../internal/OpenInvoice';
import CoreProvider from '../../core/Context/CoreProvider';
import { unformatDate } from '../internal/FormFields/InputDate/utils';
/**
 * OpenInvoiceContainer: A higher order function which returns a different class based on issuerType
 */
var withOpenInvoice = function (_a) {
    var _b;
    var type = _a.type, consentCheckbox = _a.consentCheckbox;
    return _b = /** @class */ (function (_super) {
            __extends(OpenInvoiceContainer, _super);
            function OpenInvoiceContainer() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            Object.defineProperty(OpenInvoiceContainer.prototype, "isValid", {
                /**
                 * Returns whether the component state is valid or not
                 */
                get: function () {
                    return !!this.state.isValid;
                },
                enumerable: false,
                configurable: true
            });
            /**
             * Formats props on construction time
             */
            OpenInvoiceContainer.prototype.formatProps = function (props) {
                return __assign(__assign({}, props), { data: __assign(__assign({}, props.data), { billingAddress: __assign(__assign({}, props.data.billingAddress), { country: props.countryCode || props.data.billingAddress.countryCode }), deliveryAddress: __assign(__assign({}, props.data.deliveryAddress), { country: props.countryCode || props.data.deliveryAddress.countryCode }) }) });
            };
            /**
             * Formats the component data output
             */
            OpenInvoiceContainer.prototype.formatData = function () {
                var _a = this.state.data, data = _a === void 0 ? {} : _a;
                var _b = data.personalDetails, personalDetails = _b === void 0 ? {} : _b, _c = data.billingAddress, billingAddress = _c === void 0 ? {} : _c, deliveryAddress = data.deliveryAddress, separateDeliveryAddress = data.separateDeliveryAddress;
                var firstName = personalDetails.firstName, lastName = personalDetails.lastName, _d = personalDetails.gender, gender = _d === void 0 ? 'UNKNOWN' : _d, telephoneNumber = personalDetails.telephoneNumber, shopperEmail = personalDetails.shopperEmail, dateOfBirth = personalDetails.dateOfBirth;
                return {
                    paymentMethod: {
                        type: OpenInvoiceContainer.type
                    },
                    shopperName: { firstName: firstName, lastName: lastName, gender: gender },
                    dateOfBirth: unformatDate(dateOfBirth),
                    telephoneNumber: telephoneNumber,
                    shopperEmail: shopperEmail,
                    billingAddress: billingAddress,
                    deliveryAddress: separateDeliveryAddress === 'true' ? deliveryAddress : billingAddress,
                    countryCode: billingAddress.country
                };
            };
            OpenInvoiceContainer.prototype.render = function () {
                var _this = this;
                var i18n = this.props.i18n;
                return (h(CoreProvider, { i18n: i18n, loadingContext: this.props.loadingContext },
                    h(OpenInvoice, __assign({ ref: function (ref) {
                            _this.componentRef = ref;
                        } }, this.props, this.state, { onChange: this.setState, onSubmit: this.submit, consentCheckbox: consentCheckbox, payButton: this.payButton }))));
            };
            return OpenInvoiceContainer;
        }(UIElement)),
        _b.type = type,
        _b.defaultProps = {
            onChange: function () { },
            data: { personalDetails: {}, billingAddress: {}, deliveryAddress: {} },
            visibility: {
                personalDetails: 'editable',
                billingAddress: 'editable',
                deliveryAddress: 'editable'
            }
        },
        _b;
};
export default withOpenInvoice;
