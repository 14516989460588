import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import ApplePayButton from './components/ApplePayButton';
import ApplePayService from './ApplePayService';
import { preparePaymentRequest } from './payment-request';
import { normalizeAmount } from './utils';
import defaultProps from './defaultProps';
var ApplePayElement = /** @class */ (function (_super) {
    __extends(ApplePayElement, _super);
    function ApplePayElement(props) {
        var _this = _super.call(this, props) || this;
        _this.startSession = _this.startSession.bind(_this);
        _this.submit = _this.submit.bind(_this);
        return _this;
    }
    /**
     * Formats the component props
     */
    ApplePayElement.prototype.formatProps = function (props) {
        var amount = normalizeAmount(props);
        return __assign(__assign({ onAuthorized: function (resolve) { return resolve(); }, onValidateMerchant: function (resolve, reject) { return reject('onValidateMerchant event not implemented'); } }, props), { amount: amount, onCancel: function (event) { return props.onError(event); } });
    };
    /**
     * Formats the component data output
     */
    ApplePayElement.prototype.formatData = function () {
        return {
            paymentMethod: __assign({ type: ApplePayElement.type }, this.state)
        };
    };
    ApplePayElement.prototype.submit = function () {
        this.startPayment();
    };
    ApplePayElement.prototype.startPayment = function () {
        return Promise.resolve(this.startSession(this.props.onAuthorized));
    };
    ApplePayElement.prototype.startSession = function (onPaymentAuthorized) {
        var _this = this;
        var _a = this.props, version = _a.version, onValidateMerchant = _a.onValidateMerchant, onSubmit = _a.onSubmit, onCancel = _a.onCancel, onPaymentMethodSelected = _a.onPaymentMethodSelected, onShippingMethodSelected = _a.onShippingMethodSelected, onShippingContactSelected = _a.onShippingContactSelected;
        var paymentRequest = preparePaymentRequest(__assign({ companyName: this.props.configuration.merchantName }, this.props));
        var session = new ApplePayService(paymentRequest, {
            version: version,
            onValidateMerchant: onValidateMerchant,
            onCancel: onCancel,
            onPaymentMethodSelected: onPaymentMethodSelected,
            onShippingMethodSelected: onShippingMethodSelected,
            onShippingContactSelected: onShippingContactSelected,
            onPaymentAuthorized: function (resolve, reject, event) {
                if (!!event.payment.token && !!event.payment.token.paymentData) {
                    _this.setState({ 'applepay.token': btoa(JSON.stringify(event.payment.token.paymentData)) });
                }
                onSubmit({ data: _this.data, isValid: _this.isValid }, _this);
                onPaymentAuthorized(resolve, reject, event);
            }
        });
        session.begin();
    };
    Object.defineProperty(ApplePayElement.prototype, "isValid", {
        /**
         * Validation
         *
         * @remarks
         * Apple Pay does not require any specific validation
         */
        get: function () {
            return true;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Determine a shopper's ability to return a form of payment from Apple Pay.
     * @returns Promise Resolve/Reject whether the shopper can use Apple Pay
     */
    ApplePayElement.prototype.isAvailable = function () {
        if (document.location.protocol !== 'https:') {
            return Promise.reject(new Error('Trying to start an Apple Pay session from an insecure document'));
        }
        if (!this.props.onValidateMerchant) {
            return Promise.reject(new Error('onValidateMerchant event was not provided'));
        }
        if (window.ApplePaySession && ApplePaySession.canMakePayments() && ApplePaySession.supportsVersion(this.props.version)) {
            return Promise.resolve(ApplePaySession.canMakePayments());
        }
        return Promise.reject(new Error('Apple Pay is not available on this device'));
    };
    /**
     * Renders the Apple Pay button or nothing in the Dropin
     */
    ApplePayElement.prototype.render = function () {
        if (this.props.showPayButton) {
            return h(ApplePayButton, { buttonColor: this.props.buttonColor, buttonType: this.props.buttonType, onClick: this.submit });
        }
        return null;
    };
    ApplePayElement.type = 'applepay';
    ApplePayElement.defaultProps = defaultProps;
    return ApplePayElement;
}(UIElement));
export default ApplePayElement;
