import { __assign } from "tslib";
import { FALLBACK_LOCALE } from './config';
import defaultTranslation from './locales/en-US.json';
import locales from './locales';
/**
 * Convert to ISO 639-1
 */
var toTwoLetterCode = function (locale) { return locale.toLowerCase().substring(0, 2); };
/**
 * Matches a string with one of the locales
 * @param locale -
 * @param supportedLocales -

 * @example
 * matchLocale('en-GB');
 * // 'en-US'
 */
export function matchLocale(locale, supportedLocales) {
    if (!locale || typeof locale !== 'string')
        return null;
    return supportedLocales.find(function (supLoc) { return toTwoLetterCode(supLoc) === toTwoLetterCode(locale); }) || null;
}
/**
 * Returns a locale with the proper format
 * @param localeParam -
 *
 * @example
 * formatLocale('En_us');
 * // 'en-US'
 */
export function formatLocale(localeParam) {
    var locale = localeParam.replace('_', '-');
    var format = new RegExp('([a-z]{2})([-])([A-Z]{2})');
    // If it's already formatted, return the locale
    if (format.test(locale))
        return locale;
    // Split the string in two
    var _a = locale.split('-'), languageCode = _a[0], countryCode = _a[1];
    // If the locale or the country codes are missing, return null
    if (!languageCode || !countryCode)
        return null;
    // Ensure correct format and join the strings back together
    var fullLocale = [languageCode.toLowerCase(), countryCode.toUpperCase()].join('-');
    return fullLocale.length === 5 ? fullLocale : null;
}
/**
 * Checks the locale format.
 * Also checks if it's on the locales array.
 * If it is not, tries to match it with one.
 * @param locale -
 * @param supportedLocales -
 */
export function parseLocale(locale, supportedLocales) {
    if (supportedLocales === void 0) { supportedLocales = []; }
    if (!locale || locale.length < 1 || locale.length > 5)
        return FALLBACK_LOCALE;
    var formattedLocale = formatLocale(locale);
    var hasMatch = supportedLocales.indexOf(formattedLocale) > -1;
    if (hasMatch)
        return formattedLocale;
    return matchLocale(formattedLocale || locale, supportedLocales);
}
/**
 * Formats the locales inside the customTranslations object against the supportedLocales
 * @param customTranslations -
 * @param supportedLocales -
 */
export function formatCustomTranslations(customTranslations, supportedLocales) {
    if (customTranslations === void 0) { customTranslations = {}; }
    return Object.keys(customTranslations).reduce(function (acc, cur) {
        var formattedLocale = formatLocale(cur) || parseLocale(cur, supportedLocales);
        if (formattedLocale) {
            acc[formattedLocale] = customTranslations[cur];
        }
        return acc;
    }, {});
}
var replaceTranslationValues = function (translation, values) {
    return translation.replace(/%{(\w+)}/g, function (_, k) { return values[k] || ''; });
};
/**
 * Returns a translation string by key
 * @param translations -
 * @param key -
 * @param options -
 *
 * @internal
 */
export var getTranslation = function (translations, key, options) {
    if (options === void 0) { options = { values: {}, count: 0 }; }
    var keyPlural = key + "__plural";
    var keyForCount = function (count) { return key + "__" + count; };
    if (Object.prototype.hasOwnProperty.call(translations, keyForCount(options.count))) {
        // Find key__count translation key
        return replaceTranslationValues(translations[keyForCount(options.count)], options.values);
    }
    else if (Object.prototype.hasOwnProperty.call(translations, keyPlural) && options.count > 1) {
        // Find key__plural translation key, if count greater than 1 (e.g. myTranslation__plural)
        return replaceTranslationValues(translations[keyPlural], options.values);
    }
    else if (Object.prototype.hasOwnProperty.call(translations, key)) {
        // Find key translation key (e.g. myTranslation)
        return replaceTranslationValues(translations[key], options.values);
    }
    return null;
};
/**
 * Returns an array with all the locales
 * @param locale - The locale the user wants to use
 * @param customTranslations -
 */
export var loadTranslations = function (locale, customTranslations) {
    if (customTranslations === void 0) { customTranslations = {}; }
    // Match locale to one of our available locales (e.g. es-AR => es-ES)
    var localeToLoad = parseLocale(locale, Object.keys(locales)) || FALLBACK_LOCALE;
    return __assign(__assign(__assign({}, defaultTranslation), locales[localeToLoad]), (customTranslations[locale] && customTranslations[locale]) // Merge with their custom locales if available
    );
};
