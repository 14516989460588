import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import MBWayInput from './components/MBWayInput';
import CoreProvider from '~/core/Context/CoreProvider';
import config from '~/components/MBWay/components/MBWayAwait/config';
import Await from '~/components/internal/Await';
var MBWayElement = /** @class */ (function (_super) {
    __extends(MBWayElement, _super);
    function MBWayElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MBWayElement.prototype.formatProps = function (props) {
        if (props.data) {
            props.data.email = props.data.shopperEmail || props.data.email;
            props.data.phoneNumber = props.data.telephoneNumber || props.data.phoneNumber;
        }
        return __assign({}, props);
    };
    /**
     * Formats the component data output
     */
    MBWayElement.prototype.formatData = function () {
        var paymentMethod = {
            type: MBWayElement.type,
            shopperEmail: this.state.data ? this.state.data.email : '',
            telephoneNumber: this.state.data ? this.state.data.phoneNumber : ''
        };
        return {
            paymentMethod: paymentMethod
        };
    };
    Object.defineProperty(MBWayElement.prototype, "isValid", {
        get: function () {
            return !!this.state.isValid;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MBWayElement.prototype, "displayName", {
        get: function () {
            return this.props.name;
        },
        enumerable: false,
        configurable: true
    });
    MBWayElement.prototype.render = function () {
        var _this = this;
        if (this.props.paymentData) {
            return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext },
                h(Await, { ref: function (ref) {
                        _this.componentRef = ref;
                    }, loadingContext: this.props.loadingContext, originKey: this.props.originKey, clientKey: this.props.clientKey, paymentData: this.props.paymentData, onError: this.props.onError, onComplete: this.onComplete, brandLogo: this.icon, type: config.type, messageText: this.props.i18n.get(config.messageTextId), awaitText: this.props.i18n.get(config.awaitTextId), showCountdownTimer: config.showCountdownTimer, delay: config.STATUS_INTERVAL, countdownTime: config.COUNTDOWN_MINUTES, throttleTime: config.THROTTLE_TIME, throttleInterval: config.THROTTLE_INTERVAL })));
        }
        return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext },
            h(MBWayInput, __assign({ ref: function (ref) {
                    _this.componentRef = ref;
                } }, this.props, { onChange: this.setState, onSubmit: this.submit, payButton: this.payButton }))));
    };
    MBWayElement.type = 'mbway';
    return MBWayElement;
}(UIElement));
export { MBWayElement };
export default MBWayElement;
