import { __rest } from "tslib";
import { getDecimalAmount } from '~/utils/amount-util';
var formatAmount = function (amount) { return String(getDecimalAmount(amount.value, amount.currency)); };
export var preparePaymentRequest = function (_a) {
    var countryCode = _a.countryCode, companyName = _a.companyName, amount = _a.amount, props = __rest(_a, ["countryCode", "companyName", "amount"]);
    var formattedAmount = formatAmount(amount);
    return {
        countryCode: countryCode,
        currencyCode: amount.currency,
        total: {
            label: props.totalPriceLabel,
            amount: formattedAmount,
            type: props.totalPriceStatus
        },
        lineItems: props.lineItems,
        shippingMethods: props.shippingMethods,
        shippingType: props.shippingType,
        merchantCapabilities: props.merchantCapabilities,
        supportedCountries: props.supportedCountries,
        supportedNetworks: props.supportedNetworks,
        requiredShippingContactFields: props.requiredShippingContactFields,
        requiredBillingContactFields: props.requiredBillingContactFields,
        billingContact: props.billingContact,
        shippingContact: props.shippingContact,
        applicationData: props.applicationData
    };
};
export default preparePaymentRequest;
