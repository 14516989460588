import { isReadyToPayRequest, initiatePaymentRequest } from './requests';
import { resolveEnvironment } from './utils';
var GooglePayService = /** @class */ (function () {
    function GooglePayService(props) {
        var environment = resolveEnvironment(props.environment);
        if (environment === 'TEST' && process.env.NODE_ENV === 'development') {
            console.warn('Google Pay initiated in TEST mode. Request non-chargeable payment methods suitable for testing.');
        }
        this.paymentsClient = this.getGooglePaymentsClient({ environment: environment, paymentDataCallbacks: props.paymentDataCallbacks });
    }
    /**
     * Initialize a Google Pay API client
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/client#PaymentsClient|PaymentsClient constructor}
     * @returns Google Pay API client
     */
    GooglePayService.prototype.getGooglePaymentsClient = function (paymentOptions) {
        if (window.google && window.google.payments) {
            return new google.payments.api.PaymentsClient(paymentOptions);
        }
        return null;
    };
    /**
     * Determine a shopper's ability to return a form of payment from the Google Pay API.
     * @see {@link https://developers.google.com/pay/api/web/reference/client#isReadyToPay|isReadyToPay}
     */
    GooglePayService.prototype.isReadyToPay = function (props) {
        if (!this.paymentsClient)
            return Promise.reject(new Error('Google Pay is not available'));
        return this.paymentsClient.isReadyToPay(isReadyToPayRequest(props));
    };
    GooglePayService.prototype.prefetchPaymentData = function (props) {
        if (!this.paymentsClient)
            throw new Error('Google Pay is not available');
        var paymentDataRequest = initiatePaymentRequest(props);
        return this.paymentsClient.prefetchPaymentData(paymentDataRequest);
    };
    /**
     * Show Google Pay payment sheet when Google Pay payment button is clicked
     * @returns paymentData response from Google Pay API after user approves payment
     * @see {@link https://developers.google.com/pay/api/web/reference/object#PaymentData|PaymentData object reference}
     */
    GooglePayService.prototype.initiatePayment = function (props) {
        if (!this.paymentsClient)
            throw new Error('Google Pay is not available');
        var paymentDataRequest = initiatePaymentRequest(props);
        return this.paymentsClient.loadPaymentData(paymentDataRequest);
    };
    return GooglePayService;
}());
export default GooglePayService;
